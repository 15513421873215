@mixin select-text-defaults {
  color: $grey-2;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  border-left: 2px solid #D7D7D7;
  padding-left: 10px;
}

.users-select {
  -webkit-appearance: none;
  border: none;
  background: none;
  color: inherit;
  text-align: left;

  .users-select__menu {
    width: inherit;
    box-shadow: 0 2px 18px 4px rgba(0, 0, 0, 0.03);
    margin-top: 20px;

    .users-select__menu-list {
      padding: 0;

      .users-select__option {
        @include select-text-defaults;
        display: inline-block;
      }

      .users-select__option--is-focused {
        background-color: $bg-grey;
      }

      .users-select__option--is-selected {
        background-color: $bg-grey;
        &::after {
          content: '';
          display: inherit;
          width: 6px;
          height: 12px;
          border: solid #959595;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          position: absolute;
          left: 135px;
          margin-top: 2px;
        }
      }
    }

  }

  .users-select__control {
    background-color: inherit;
    border: none;

    .users-select__indicator {
      padding: 0px;

      svg {
        opacity: 0.68;
        color: $grey;
      }
    }

    .users-select__indicator-separator {
      width: 0px;
    }

    .users-select__value-container {
      color: $grey;
      width: 135px;

      .users-select__single-value {
        width: 85%;
        @include select-text-defaults;
      }

      .users-select__placeholder {
        width: 85%;
        @include select-text-defaults;
      }
    }
  }

  .users-select__control--menu-is-open {
    box-shadow: none;
    &::before {
      content: "";
      background-color: #FF3C58;
      width: 125px;
      height: 1px;
      position: absolute;
      top: 54px;
      left: 16px;
    }
  }

  .users-select__control--is-focused {
    box-shadow: none;
  }

}
