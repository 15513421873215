.lb-popover {
  display: none;
  position: absolute;
  box-sizing: border-box;
  width: 250px;
  top: 0px;
  left: 0px;
  margin-left: -125px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #FFF;
  z-index: 100;
  padding: 20px;
  color: #5f5f5f;

  &::before,
  &:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
  }

  &::before {
    top: -16px;
    z-index: 101;
    left: 50%;
    margin-left: -12px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 16px solid #FFF;
  }

  &:after {
    top: -20px;
    left: 50%;
    margin-left: -16px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 20px solid rgba(0, 0, 0, 0.01);
  }
}
