.labor-dropdown {
  position: relative;
  display: inline-block;
}

.labor-dropdown > span {
  text-align: right;
}

.labor-dropdown > span i {
  font-size: 18px;
  float: right;
  padding-top: 3px;
  padding-left: 8px;
  padding-right: 8px;
}

.labor-dropdown .lb-ddown-container {
  width: 250px;
  text-align: left;
  padding: 10px 0;
  position: absolute;
  top: 42px;
  right: 17px;
  margin-right: -125px;
  box-shadow: 0 2px 18px 4px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  z-index: 100;
}

.labor-dropdown .lb-ddown-container::before,
.labor-dropdown .lb-ddown-container:after {
  content: '';
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.labor-dropdown .lb-ddown-container::before {
  top: -16px;
  z-index: 101;
  left: 50%;
  margin-left: -12px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 16px solid #fff;
}

.labor-dropdown .lb-ddown-container:after {
  top: -20px;
  left: 50%;
  margin-left: -16px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 20px solid rgba(0, 0, 0, 0.01);
}

.labor-dropdown .lb-ddown-container ul {
  max-height: 330px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}

.labor-dropdown .lb-ddown-container ul li {
  padding: 10px 20px;
  cursor: default;
  position: relative;
  outline: none;
}

.labor-dropdown .lb-ddown-container ul li.selected,
.labor-dropdown .lb-ddown-container ul li:hover {
  background-color: #f6f7fb;
}

.labor-dropdown .lb-ddown-container ul li.selected:after {
  content: 'check';
  font-family: 'Material Icons';
  position: absolute;
  top: 11px;
  right: 20px;
  color: #b7b7b7;
  font-size: 20px;
}

.labor-dropdown .lb-ddown-container ul li {
}
