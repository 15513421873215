// Code from: https://gist.github.com/indrekpaas/4d6dfa5c29da805219d4149352c4703b
@function svg-encode($svg) {
  // Add missing namespace
  @if not str-index($svg, ' xmlns="http://www.w3.org/2000/svg"') {
    $svg: str-insert($svg, ' xmlns="http://www.w3.org/2000/svg"', 5);
  }

  // Encode characters
  $encode: (
    '"': "%22",
    "#": "%23",
    "%": "%25",
    "<": "%3C",
    ">": "%3E",
    "{": "%7B",
    "}": "%7D",
  );

  $string: "";
  $character: "";

  @for $i from 1 through str-length($svg) {
    $character: str-slice($svg, $i, $i);

    @if index(map-keys($encode), $character) {
      $character: map-get($encode, $character);
    }

    $string: $string + $character;
  }

  // Return data URI
  @return url("data:image/svg+xml,#{$string}");
}

@function icon($icon-name) {
  $icons: (
    add-blue: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#4F587A" fill-rule="evenodd" d="M11.425 8.575h-2.85v2.85a.575.575 0 0 1-1.15 0v-2.85H4.574A.59.59 0 0 1 4 8c0-.317.257-.575.575-.575h2.85v-2.85C7.425 4.257 7.683 4 8 4c.145 0 .292.063.402.173.11.11.173.256.173.402v2.85h2.851A.59.59 0 0 1 12 8a.575.575 0 0 1-.575.575m2.03-6.424A7.93 7.93 0 0 0 7.721.006C3.313.159-.15 3.87.005 8.278a7.94 7.94 0 0 0 2.541 5.568 7.915 7.915 0 0 0 5.733 2.148c4.408-.154 7.869-3.865 7.716-8.273a7.952 7.952 0 0 0-2.54-5.57"/></svg>'
  );

  $icon: map-get($icons, $icon-name);
  @return svg-encode($icon);
}

.icon-svg {
  display: inline-block;
  font-size: 0;
  width: 18px;
  height: 18px;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.icon-add-blue {
  background-image: icon(add-blue);
}
