.project-header-section {
  padding: 0 44px 30px 44px;

  .project-header-title {
    height: 24px;

    div:nth-of-type(1) {
      float: left;
      font-size: 24px;
      font-weight: 700;
      color: $dusk;
    }
  }

  .project-navigation {
    position: relative;
    height: 49px;
    margin: 16px 0;
    z-index: 0;

    .project-navigation-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      float: left;
      height: 100%;

      .project-navigation-btn {
        font-weight: 400;
        margin-right: 48px;
        padding: 0 13px 8px 13px;
        overflow: hidden;
      }

      .project-navigation-btn:last-child {
        margin-right: 0;
      }

      .project-navigation-btn:hover {
        color: $dusk;
      }

      .project-navigation-btn_active {
        border-bottom: 3px solid $dusk;
        font-weight: 600;
        color: $dusk;
        z-index: 1;
      }
    }

    .project-btn-add {
      float: right;
      font-size: 16px;
      margin-bottom: 12px;
    }

    .project-header-line {
      height: 1px;
      background-color: #d7d7d7;
      width: 100%;
      position: absolute;
      bottom: 1px;
      z-index: 0;
    }
  }

  .project-filters {
    clear: both;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 25px;

    .project-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 24px;

      span {
        font-weight: 400;
        font-size: 12px;
        margin: 0px 12px;
        color: $grey-2;
      }
    }
  }
}

.second-projects-table {
  margin-top: 20px;
}

.project-table-body {
  .opactity-50 {
    opacity: 0.5;
  }

  .project-icon {
    margin-right: 10px;
    margin-bottom: 2px;
    color: var(--project-color);
  }

  tr {
    cursor: pointer;
    &:hover {
      td:nth-of-type(1) {
        font-weight: bold;
        color: var(--project-color);
      }
    }
  }
}
