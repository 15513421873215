[popover-wrapper] {
  position: relative;
  display: inline-block; }
  [popover-wrapper] [popover-trigger] {
    cursor: pointer; }
  [popover-wrapper] [popover-html] {
    display: none;
    position: absolute;
    box-sizing: border-box;
    width: 250px;
    top: 50px;
    right: 13px;
    margin-right: -125px;
    box-shadow: 0 2px 18px 4px rgba(0, 0, 0, 0.03);
    background-color: #FFF;
    z-index: 100; }
    [popover-wrapper] [popover-html]::before, [popover-wrapper] [popover-html]:after {
      content: '';
      width: 0;
      height: 0;
      display: block;
      position: absolute; }
    [popover-wrapper] [popover-html]::before {
      top: -16px;
      z-index: 101;
      left: 50%;
      margin-left: -12px;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 16px solid #FFF; }
    [popover-wrapper] [popover-html]:after {
      top: -20px;
      left: 50%;
      margin-left: -16px;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 20px solid rgba(0, 0, 0, 0.01); }
