list-select {
  ul {
    overflow-y: auto;
    list-style: none;
    padding: 0;
    
    li {
      position: relative;
      outline: none;
      cursor: pointer;
      padding: 10px 20px;

      &.empty-option {
        color: #c7c7c7;
      }

      &.selected,
      &:hover {
        background-color: #f6f7fb;
      }
      
      &.selected:after {
        content: 'check';
        font-family: 'Material Icons';
        position: absolute;
        top: 11px;
        right: 20px;
        color: #b7b7b7;
        font-size: 20px;
      }
    }
  }
}