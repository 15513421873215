/* The switch:
 * ---------------------------------------------------------------- */

#wrapper md-switch .md-bar {
  width: 27px;
  height: 8px;
  position: absolute;
  top: 8px;
  left: 1px;
  border-radius: 8px;
}

#wrapper md-switch.md-checked .md-bar {
  background-color: rgb(255, 118, 151);
}

#wrapper md-switch .md-thumb {
  width: 16px;
  height: 16px;
  margin: 0;
  outline: none;
}

#wrapper md-switch.md-checked .md-thumb::before {
  background-color: rgb(255, 57, 90);
}

#wrapper md-switch[disabled] .md-bar {
  background-color: #e85d7e; // set disabled bar color
}

#wrapper md-switch[disabled] .md-thumb::before {
  background-color: #e85d7e; // set disabled bar color
}

#wrapper md-switch .md-thumb-container {
  top: 4px;
}
