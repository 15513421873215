md-input-container.md-input-focused:not(.md-input-has-value) md-select .md-select-value,
md-input-container.md-input-focused:not(.md-input-has-value) md-select .md-select-value.md-select-placeholder,
md-select-menu md-content md-option[selected],
md-select-menu md-content md-option[selected]:focus,
md-select-menu.md-default-theme md-content md-option,
md-select-menu md-content md-option {
  color: #7d7d7d;
}

md-select {
  // display: inline-block;
  &:not([disabled]):focus .md-select-value {
    color: #7d7d7d;
  }

  &.ng-invalid.ng-touched .md-select-value {
    color: #7d7d7d !important;
  }

  .md-select-value {
    padding: 0;

    .md-select-icon {
      width: auto;
      margin: 0;
      transform: none;
      margin-left: 5px;

      &::after {
        content: '\E313';
        font-family: 'Material Icons';
        display: initial;
        font-size: 18px;
      }
    }
  }

  &:not([disabled]) {
    &:focus .ng-invalid.ng-touched .md-select-value {
      padding: 0;
    }
    &.ng-invalid.ng-touched .md-select-value {
      padding: 0px;
    }
  }
}

md-select-menu {
  position: absolute;
  text-align: left;
  padding: 10px 0;
  box-shadow: 0 2px 18px 4px rgba(0, 0, 0, .03);
  background-color: #fff;
  z-index: 100;

  &:not(.md-overflow) md-content {
    padding: 0;
  }

  md-content {
    md-optgroup > label {
      background: transparent;
      font-weight: bold;
      font-size: 0.8em;
    }

    md-option {
      color: #7d7d7d;
      padding: 10px 75px 10px 20px;
      cursor: default;
      position: relative;
      outline: none;
      height: auto;

      &[selected] {
        background-color: transparent;
      }

      &:not([disabled]):focus,
      &:not([disabled]):hover {
        background-color: #f6f7fb;
      }

      &[selected]::after {
        content: "check";
        font-family: Material Icons;
        position: absolute;
        top: 11px;
        right: 20px;
        color: #b7b7b7;
        font-size: 20px;
      }
    }
  }
}

md-checkbox.md-default-theme.md-checked .md-icon, md-checkbox.md-checked .md-icon {
  background-color: #ff3c58;
}
