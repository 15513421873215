/*Notificações
*---------------------------------------*/
    

md-toast{
  &.md-success-theme {
    .md-toast-content {
      background-color: #fff;
      border-left: 6px solid #5aa700;
      color: #484848;
      &::before {
        display: inline-block;
        content: "\E86C";
        font-family: "Material Icons";
        color: #5aa700;
        visibility: visible;
        min-height:auto;
        font-size: 1.5em;
      }
    }
  }
  
  &.md-error-theme {
    .md-toast-content {
      background-color: #fff;
      border-left: 6px solid #e71d32;
      color: #000;
      &::before {
        display: inline-block;
        content: "\E14B";
        font-family: "Material Icons";
        color: #e71d32;
        visibility: visible;
        min-height:auto;
        font-size: 1.5em;
      }
    }
  }

  &.md-warning-theme {
    .md-toast-content {
      background-color: #fff;
      border-left: 6px solid #efc100;
      color: #000;
      &::before {
        display: inline-block;
        content: "\E002";
        font-family: "Material Icons";
        color: #efc100;
        visibility: visible;
        min-height:auto;
        font-size: 1.5em;
      }
    }
  }
}