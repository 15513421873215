/* Modal
 * ---------------------------------------------------------------- */

md-backdrop.md-opaque {
  background-color: rgba(246, 250, 251, 0.95);
  opacity: 1;
}

body md-dialog {
  box-shadow: 0 0 8px 12px rgba(0,0,0,0.02) !important;

  &.md-default-theme {
    color: #7d7d7d;
    text-align: center;
    padding: 40px 0;
    border-radius: 0;
    box-shadow: 0 0 8px 12px rgba(0,0,0,0.02);
  }

  &.modal-tabs {
    padding: 0;
    min-width: 580px;
    min-height: 285px;

    md-dialog-content {
      md-tab-item.md-tab {
        text-transform: initial;

        &:hover:not(.md-active) {
          color: rgba(0,0,0,0.8);
        }
      }

      button.md-icon-button {
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 2;
      }

      md-tabs {
        md-tabs-wrapper {
          background: #f6f7fb;
        }

        md-tabs-content-wrapper md-content {
          padding: 24px 42px;
          background-color: transparent;
        }
      }
    }
  }

  md-dialog-content {
    .md-dialog-content {
      padding: 0 42px 24px 42px;
    }

    h2 {
      margin-bottom: 40px;
    }
  }

  md-toolbar {
    background-color: #FFF !important;
    .md-toolbar-tools {
      md-icon {
        color: #999;
      }
    }
  }

  .md-actions,
  md-dialog-actions {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;

    .md-confirm-button {
      color: #FFF !important;
      padding: 2px 55px 1px;
      text-transform: none;
      margin-left: 0;
      background-color: #ff3c58 !important;

      &:active,
      &:hover {
        background-color: #ff3c58;
      }
    }

    .md-cancel-button {
      min-width: 36px;
      color: #999 !important;
      font-family: 'Material Icons';
      background: none !important;
      position: absolute;
      top: 4px; right: 13px;

      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -moz-font-feature-settings: 'liga';
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .labor-modal {
    color: #7d7d7d;
    padding: 40px 0;
    border-radius: 0;
    box-shadow: 0 0 8px 12px rgba(0,0,0,0.02);
    font-size: 0.86em !important;
    font-family: "Open Sans", sans-serif !important;
  }

  .save-report {
    position: relative;
    bottom: 35px;
    .save-report-icon {
      font-size: 28px;
    }
  }

  &.modal-form {
    padding-bottom: 40px;
    min-width: 580px;
    input,
    input.md-input,
    .md-text,
    md-option.md-text {
      color: #484848;
    }

    .md-dialog-content {
      padding: 24px 42px;
    }

    md-dialog-actions {
      button.md-raised.md-confirm-button.md-primary,
      button.md-button.md-raised,
      button.md-button:not([disabled]).md-raised:active,
      button.md-button:not([disabled]).md-raised:focus,
      button.md-button:not([disabled]).md-raised:visited,
      button.md-button.md-raised:not([disabled]) {
        box-shadow: none;
        outline: none;
      }
    }

  }

  .modal-content-text {
    text-align:center;
    width: 300px;
  }

  md-datepicker {
    .md-datepicker-button {
      margin: -12px 0 !important;
    }
    .md-datepicker-input-container {
      margin-left: 0px !important;
      width: 206px;
    }
  }

  md-select.select-modal {
    .md-text {
      font-size: 0.86em;
    }
    md-select-value {
      padding-bottom: 2px !important;
    }
  }
}

.fullscreen-modal {
  padding: 3.2rem 0;
  align-self: start;
  min-width: 500px;
  background-color: transparent;
  box-shadow: none !important;
  position: initial;
  max-height: 100%;
  max-width: 100%;
  overflow: visible;

  .md-button.md-icon-button.close-button {
    position: absolute;
    top: 2rem;
    right: 2rem;

    &:hover {
      background-color: rgba(158,158,158,0.2);
    }
  }

  md-dialog-content {
    header {
      text-align: center;
      margin-bottom: 2.5rem;

      h2 {
        font-size: 2rem;
        margin: 0;
        font-weight: 500;
        letter-spacing: -1px;
      }

      h3 {
        max-width: 500px;
        margin-top: 0.1rem;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.1rem;
        font-weight: 300;
      }
    }

    input {
      font-size: 1.8rem;
      height: auto;
    }
  }

  md-dialog-actions {
    margin-top: 2.5rem;
    flex-direction: column;

    button.md-button:not(.md-fab):not(.md-icon-button):not(.md-cancel-button) {
      font-size: 1.3rem;
      padding: 0.7rem 1.5rem;
      border-radius: 0.25em;
    }

    button.md-button.md-link:not(.md-fab):not(.md-icon-button):not(.md-cancel-button) {
      font-size: 1rem;

      md-icon {
        font-size: 1.2rem;
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }
}

.welcome-modal {
  padding: 3.2rem;
  .md-button.md-icon-button.close-button {
    position: absolute;
    top: 2rem;
    right: 2rem;

    &:hover {
      background-color: rgba(158,158,158,0.2);
    }
  }
}
