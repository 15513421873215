#reports {

  // HEADER
  .header-options md-input-container {
    md-icon {
      font-size: 1em;
      line-height: 1.5em;
      margin-left: 2px;
    }
  }

  /* SUMMARY SECTION
   * ------------------------------------------------------------------ */
  .summary {
    margin-bottom: 70px;

    & > .wrap-container {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    & > header {
      margin-bottom: 10px;
    }

    & > footer {
      margin-top: 10px;
    }

    table {
      width: 100%;

      tr {
        border-bottom: 1px solid rgb(239, 239, 241);

        &:last-child {
          border-bottom: none;
        }

        td {
          padding: 10px 0;

          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  /* LOG SECTION
  * ------------------------------------------------------------------ */
  .log {
    margin-bottom: 70px;

    header {
      margin-bottom: 0;
      md-input-container {
        margin: 0;
      }
    }
  }

  .log table tr td.small-col {
    width: 85px;
  }
}

.pink-button {
  color: #ff3c58 !important;
}

.load-more-btn {
  margin: 20px
}

.lb-popover {
  overflow-y: auto;
  height: 80%;
  .select-all {
    border-bottom: 1px solid #e6e7eb;
    padding-bottom: 20px;
    margin-bottom: 20px;
    md-checkbox {
      margin-bottom: 0;
    }
  }
  .list {
    div:last-child {
      md-checkbox {
        margin-bottom: 0;
      }
    }
  }

  .popover-projects {
    .tab-labels {
      margin-bottom: 20px;
      border-bottom: 1px solid #e6e7eb;

      .tab-label {
        justify-content: space-between;
        font-size: 14px;
        font-weight: 600;
        padding: 14px 5px 5px;
        text-align: center;
        color: #959595;

        &.active {
          color: #4680fe;
          margin-bottom: -1px;
          border-bottom: 2px solid #4680fe;
        }

        &:hover {
          color: #4680fe;
        }
      }
    }
    .tabs {
      .tab:last-child {
        md-checkbox {
          margin-bottom: 0;
        }
      }
    }
    .empty-list {
      color: #d7d7d7;
      font-weight: 600;
    }
  }

  .popover-download {
    .format {
      justify-content: space-between;
      font-size: 16px;
      font-weight: 600;
      padding: 14px 5px 5px;
      color: #959595;
      &:hover {
        color: #FF3C58;
      }
    }
  }

  .period-picker {
    .period-inputs {
      & > div:first-child {
        margin-right: 15px;
      }

      & > div:last-child {
        margin-left: 15px;
      }

      label {
        color: #d7d7d7;
        font-weight: 600;
        font-size: 0.9em;
      }
      input {
        border: 0;
        border-bottom: 2px solid #f4f4f4;
        padding: 10px 0;
        color: #959595;
        font-size: 0.9em;

        &:focus {
          outline: none;
          border-color: #b2b2b2;
        }
      }
    }

    date-range-picker {
      font-size: 0.9em;
    }

    table.period-options {
      color: #7d7d7d;
      clear: both;
      width: 100%;
      border-top: 2px solid #f4f4f4;
      margin-top: 30px;
      padding-top: 30px;
      text-align: center;
      font-size: 0.9em;
      font-weight: 600;
      td:hover {
        color: #FF3C58;
      }
    }
  }
}
