[datetimepicker-trigger] {
  cursor: pointer; }

.datetimepicker-parent {
  position: relative; }

datetimepicker {
  font-size: 14px;
  z-index: 999991;
  position: absolute;
  top: 0;
  right: 5px;
  background: #ffffff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05);
  width: 340px;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 5px;
  max-width: none !important; }
  datetimepicker::before, datetimepicker:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute; }
  datetimepicker::before {
    top: -12px;
    z-index: 999992;
    left: 50%;
    margin-left: -12px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #FFF; }
  datetimepicker div.month-selector {
    color: #fe3c57;
    text-align: center;
    text-transform: capitalize;
    margin: 30px 0 20px 0; }
    datetimepicker div.month-selector md-icon {
      color: #fe3c57;
      font-size: 1.2em;
      line-height: 1.5em; }
  datetimepicker div.datetimepicker-inputs div:first-child {
    margin-right: 10px; }
  datetimepicker div.datetimepicker-inputs div:last-child {
    margin-left: 10px; }
  datetimepicker div.datetimepicker-inputs div label {
    color: #d6d6d6;
    font-weight: bold;
    text-align: left; }
  datetimepicker div.datetimepicker-inputs div input {
    color: #888888;
    padding: 7px 0;
    border: 0;
    border-bottom: 1px solid #f2f2f2; }
    datetimepicker div.datetimepicker-inputs div input:focus {
      outline: none;
      border-color: #b2b2b2; }
    datetimepicker div.datetimepicker-inputs div input[disabled] {
      background: transparent;
      border-bottom: 1px solid #f2f2f2; }
    datetimepicker div.datetimepicker-inputs div input::placeholder {
      color: #e3e3e3; }
  datetimepicker table {
    width: 100%;
    font-size: 1em !important; }
    datetimepicker table tbody {
      font-size: 0.86em;
      border: none !important; }
      datetimepicker table tbody .week-day-line {
        cursor: default; }
        datetimepicker table tbody .week-day-line span {
          pointer-events: none;
          font-weight: bold;
          font-size: 0.9em;
          color: #d6d6d6; }
      datetimepicker table tbody .mounth-day-line span {
        margin: 0.3em; }
      datetimepicker table tbody tr {
        border: none !important; }
        datetimepicker table tbody tr td {
          padding: 0 !important; }
          datetimepicker table tbody tr td span {
            margin: 0.5em;
            cursor: pointer;
            color: #7d7d7d;
            border-radius: 50%;
            width: 2.5em;
            display: inline-block;
            height: 2.5em;
            text-align: center;
            line-height: 2.5em;
            font-size: 1em; }
            datetimepicker table tbody tr td span:hover {
              background-color: #f3f3f3; }
            datetimepicker table tbody tr td span.today {
              background-color: #d7d7d7; }
            datetimepicker table tbody tr td span.selected {
              background-color: #fe3b5b;
              color: #ffffff; }
            datetimepicker table tbody tr td span.out {
              color: #e0e0e0; }
