[datetimepicker-trigger] {
  cursor: pointer;
}

.datetimepicker-parent {
  position: relative;
}

datetimepicker {
  font-size: 14px;
  z-index: 999991;
  position: absolute;
  top: 0;
  right: 5px;
  background: #ffffff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05);
  width: 340px;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 5px;
  max-width: none !important;

  &::before,
  &:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
  }

  &::before {
    top: -12px;
    z-index: 999992;
    left: 50%;
    margin-left: -12px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #FFF;
  }

  div.month-selector {
    color: #fe3c57;
    text-align: center;
    text-transform: capitalize;
    margin: 30px 0 20px 0;
    md-icon {
      color: #fe3c57;
      font-size: 1.2em;
      line-height: 1.5em;
    }
  }

  div.datetimepicker-inputs {
    div {
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
      label {
        color: #d6d6d6;
        font-weight: bold;
        text-align: left;
      }
      input {
        color: #888888;
        padding: 7px 0;
        border: 0;
        border-bottom: 1px solid #f2f2f2;

        &:focus {
          outline: none;
          border-color: darken(#f2f2f2, 25%);
        }
        &[disabled] {
          background: transparent;
          border-bottom: 1px solid #f2f2f2;
        }

        &::placeholder {
          color: lighten(#d6d6d6, 5%);
        }
      }
    }
  }
  table {
    width: 100%;
    font-size: 1em !important;
    tbody {
      font-size: 0.86em;
      border: none !important;

      .week-day-line {
        cursor: default;
        span {
          pointer-events: none;
          font-weight: bold;
          font-size: 0.9em;
          color: #d6d6d6;
        }
      }

      .mounth-day-line {
        span {
          margin: 0.3em;
        }
      }

      tr {
        border: none !important;

        td {
          padding: 0 !important;
          span {
            margin: 0.5em;
            cursor: pointer;
            color: #7d7d7d;
            border-radius: 50%;
            width: 2.5em;
            display: inline-block;
            height: 2.5em;
            text-align: center;
            line-height: 2.5em;
            font-size: 1em;

            &:hover {
              background-color: #f3f3f3;
            }
            &.today {
              background-color: #d7d7d7;
            }
            &.selected {
              background-color: #fe3b5b;
              color: #ffffff;
            }
            &.out {
              color: #e0e0e0;
            }
          }
        }
      }
    }
  }
}