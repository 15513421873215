#reports {
  /* SUMMARY SECTION
   * ------------------------------------------------------------------ */
  /* LOG SECTION
  * ------------------------------------------------------------------ */ }
  #reports .header-options md-input-container md-icon {
    font-size: 1em;
    line-height: 1.5em;
    margin-left: 2px; }
  #reports .summary {
    margin-bottom: 70px; }
    #reports .summary > .wrap-container {
      padding-top: 30px;
      padding-bottom: 30px; }
    #reports .summary > header {
      margin-bottom: 10px; }
    #reports .summary > footer {
      margin-top: 10px; }
    #reports .summary table {
      width: 100%; }
      #reports .summary table tr {
        border-bottom: 1px solid #efeff1; }
        #reports .summary table tr:last-child {
          border-bottom: none; }
        #reports .summary table tr td {
          padding: 10px 0; }
          #reports .summary table tr td:last-child {
            text-align: right; }
  #reports .log {
    margin-bottom: 70px; }
    #reports .log header {
      margin-bottom: 0; }
      #reports .log header md-input-container {
        margin: 0; }
  #reports .log table tr td.small-col {
    width: 85px; }

.pink-button {
  color: #ff3c58 !important; }

.load-more-btn {
  margin: 20px; }

.lb-popover {
  overflow-y: auto;
  height: 80%; }
  .lb-popover .select-all {
    border-bottom: 1px solid #e6e7eb;
    padding-bottom: 20px;
    margin-bottom: 20px; }
    .lb-popover .select-all md-checkbox {
      margin-bottom: 0; }
  .lb-popover .list div:last-child md-checkbox {
    margin-bottom: 0; }
  .lb-popover .popover-projects .tab-labels {
    margin-bottom: 20px;
    border-bottom: 1px solid #e6e7eb; }
    .lb-popover .popover-projects .tab-labels .tab-label {
      justify-content: space-between;
      font-size: 14px;
      font-weight: 600;
      padding: 14px 5px 5px;
      text-align: center;
      color: #959595; }
      .lb-popover .popover-projects .tab-labels .tab-label.active {
        color: #4680fe;
        margin-bottom: -1px;
        border-bottom: 2px solid #4680fe; }
      .lb-popover .popover-projects .tab-labels .tab-label:hover {
        color: #4680fe; }
  .lb-popover .popover-projects .tabs .tab:last-child md-checkbox {
    margin-bottom: 0; }
  .lb-popover .popover-projects .empty-list {
    color: #d7d7d7;
    font-weight: 600; }
  .lb-popover .popover-download .format {
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 5px 5px;
    color: #959595; }
    .lb-popover .popover-download .format:hover {
      color: #FF3C58; }
  .lb-popover .period-picker .period-inputs > div:first-child {
    margin-right: 15px; }
  .lb-popover .period-picker .period-inputs > div:last-child {
    margin-left: 15px; }
  .lb-popover .period-picker .period-inputs label {
    color: #d7d7d7;
    font-weight: 600;
    font-size: 0.9em; }
  .lb-popover .period-picker .period-inputs input {
    border: 0;
    border-bottom: 2px solid #f4f4f4;
    padding: 10px 0;
    color: #959595;
    font-size: 0.9em; }
    .lb-popover .period-picker .period-inputs input:focus {
      outline: none;
      border-color: #b2b2b2; }
  .lb-popover .period-picker date-range-picker {
    font-size: 0.9em; }
  .lb-popover .period-picker table.period-options {
    color: #7d7d7d;
    clear: both;
    width: 100%;
    border-top: 2px solid #f4f4f4;
    margin-top: 30px;
    padding-top: 30px;
    text-align: center;
    font-size: 0.9em;
    font-weight: 600; }
    .lb-popover .period-picker table.period-options td:hover {
      color: #FF3C58; }
