.fullscreen-modal project-settings {
  md-dialog-content {
    input {
      font-size: 1rem;
    }

    .project-name input {
      font-size: 1.5rem;
    }

    .project-period {
      md-input-container:first-child {
        margin-right: 1.5rem;
      }
    }

    h3 {
      color: #5f5f5f;
      font-weight: 700;
      letter-spacing: -1px;
    }

    .project-categories__card {
      display: flex;
      flex-direction: row;
    }
  }
  md-dialog-actions {
    button.md-button.md-link:not(.md-fab):not(.md-icon-button):not(.md-cancel-button).delete-project {
      color: red;
    }
  }

  .label-select {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: auto;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 13px;
    padding-left: 3px;
    color: rgba(0,0,0,0.54);
  }
}