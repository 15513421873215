#task-bar {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  transition: top 0.3s linear;
  color: #7d7d7d;
  background-color: #FFF;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05);

  &.nav-up {
    top: -120px;
  }

  & > div {
    margin: 1rem;
    md-input-container {
      margin: 0;
    }
    & > md-input-container:first-child {
      margin-bottom: 1rem;
    }
    .task-bar__project {
      md-input-container {
        overflow: hidden;
        margin-right: 2rem;
      }
    }
    .task-bar__planned-task {
      display: flex;
      margin-right: 3rem;
      width: max-content;
    }
  }

  .task-bar__submit button {
    margin: 0 0 0 1rem;
  }

  .play-button {
    color: white;
  }

  @media (min-width: 600px) {
    width: 85%;

    &.nav-up {
      top: -70px;
    }

    & > div {
      margin: 0.8rem 15px 0.8rem 40px;

      & > md-input-container:first-child {
        margin-bottom: 0;
      }
    }


    // Remove border-bottom dos inputs
    md-input-container .md-input,
    md-select.md-default-theme .md-select-value,
    md-select .md-select-value,
    md-select:not([disabled]):focus .md-select-value,
    md-select.md-default-theme.ng-invalid.ng-touched .md-select-value,
    md-select.ng-invalid.ng-touched .md-select-value {
        border: none !important;
    }
  }

  md-select {
    display: inline-block;
  }

  &:after {
    content:'';
    display: block;
    clear: both;
  }

  .md-button.md-fab {
    width: 2.3rem;
    height: 2.3rem;
    line-height: 2.3rem;
    margin-left: 1rem;
    background-color:#ff3c58;
  }

  .md-button.md-fab:hover {
    background-color:#ff3c58;
  }

}
