.boards-page-board-section-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0 !important;
}

.md-select-menu-container {
  &.trello-select {
    md-select-menu {
      padding: 0;
      min-width: 252px;
    }

    md-option {
      height: 20px;

      .label-text {
        color: white;
        font-weight: bold;
      }

      &[selected]:after {
        color: white
      }
    }
  }
}

div.project-trello-container {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-right: 75px;

  .dialog-actions-container {
    margin-top: 44px;
    border-top: 1px solid rgba(150, 150, 150, 0.3);
    padding-top: 35px;
  }

  button.md-confirm-button {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1rem;
    padding: 0 22px !important;

    &:first-child {
      margin-left: 0px;
    }

    &[type=button] {
      font-weight: 600;
      background-color: #e8eaf3;
      color: #4f587a;
    }

    &[type=submit] {
      font-weight: 600;
      color: #ffffff;
    }
  }

  header {
    h2 {
      color: #7d7d7d;
      font-size: 1rem;
      font-weight: bold;
    }

    .info {
      color: #5f5f5f;
    }
  }

  section {
    margin-right: 80px;

    .sub-header {
      color: #7d7d7d;
      font-size: 1rem;
      font-weight: bold;
    }

    .label-select {
      font-size: 0.8125rem;
      color: #7d7d7d;
    }

    &.board-lists {
      md-input-container:last-child {
        margin-top: 10px;
      }
    }
  }

  .trello-board {
    margin-top: 20px;
    min-width: 186px;

    trello-board-tile a {
      margin-top: 15px;
    }
  }

  md-select {
    background-color: #ffffff;
    border: solid 1px rgba(149, 149, 149, 0.2) !important;
    padding: 14px 20px !important;
    min-width: 252px;
    margin-top: 5px;

    md-select-value {
      border-bottom-style: none !important;
      span {
        font-size: 1rem;
        font-weight: normal;
      }
    }

    &.trello-label {
      md-select-value {
        span {
          color: white;
          font-weight: bold;
        }
      }
    }

  }
}
