.project-category-list {
  margin-bottom: 2rem;

  article.dashboard-project {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    margin-bottom: 1rem;
    border: 1px solid rgba(150, 150, 150, 0.3);
    box-shadow: rgba(150, 150, 150, 0.1) 0 1px 10px;
    -webkit-transition: box-shadow .2s ease, background-color .2s ease;
    transition: box-shadow .2s ease, background-color .2s ease;
    
    &:hover {
      background-color: #fff;
      box-shadow: rgba(150, 150, 150, 0.2) 0 1px 20px;
    }
  
    .dashboard-project__link {
      padding: 1.318rem 1.618rem;
      padding-right: 0;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      display: block;
      color: #959595;
      text-decoration: none;
      
      h2 {
        font-size: 1.2rem;
        font-weight: 500;
        margin: 0;
        -webkit-transition: color .2s ease;
        transition: color .2s ease;
        line-height: 1.2;
        overflow: hidden;
        max-width: 90%;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  
    .dashboard-project__extra-info {
      padding-right: 1.618rem;
      width: 25%;
      text-align: right;
      font-size: 0.8rem;
  
      .dashboard-project__created-by__user {
        color: #5f5f5f;
      }
    }
  }
}