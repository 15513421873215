@font-face {
  font-family: "Open Sans";
  src: url("/fonts/OpenSans-Regular-webfont.woff2") format("woff2"),
       url("/fonts/OpenSans-Regular-webfont.woff") format("woff");
}

@import './_icons';
@import './_colors';
@import './_buttons';
@import './selects/index.scss';
@import './_table';
@import './pages/index.scss';
