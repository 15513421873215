@charset "UTF-8";
@import url(~intro.js/introjs.css);
header {
  margin-bottom: 40px;
  /* Estiliza barra de opções no header */
  /* Estiliza links e ícones dos headers */ }
  header.header-options {
    border-bottom: 1px solid #d7d7d7;
    margin-left: 40px;
    margin-right: 75px;
    /* Remove border-bottom dos inputs */ }
    header.header-options:after {
      content: '';
      display: block;
      clear: both; }
    header.header-options md-input-container .md-input,
    header.header-options md-select.md-default-theme .md-select-value,
    header.header-options md-select .md-select-value,
    header.header-options md-select:not([disabled]):focus .md-select-value,
    header.header-options md-select.md-default-theme.ng-invalid.ng-touched .md-select-value,
    header.header-options md-select.ng-invalid.ng-touched .md-select-value {
      border: none !important; }
    header.header-options md-input-container {
      margin: 0 10px 0 0;
      margin-bottom: -1px;
      border-bottom: solid 1px transparent; }
      header.header-options md-input-container:hover {
        border-bottom-color: #FF3C56; }
      header.header-options md-input-container .md-select-icon {
        margin-top: 4px;
        color: #959595; }
    header.header-options .header-icons button[disabled='disabled'] {
      opacity: 0.3;
      color: #8c8c8c; }
      header.header-options .header-icons button[disabled='disabled']:hover {
        color: #8c8c8c; }
    header.header-options .header-icons button .material-icons {
      color: #a0a0a0;
      font-size: 20px; }
      header.header-options .header-icons button .material-icons.enable {
        color: #ff3c58; }
    header.header-options .header-icons button:hover .material-icons {
      color: #8c8c8c; }
  header a,
  header md-icon {
    font-size: 1em;
    color: #959595; }
  header md-icon {
    margin-bottom: 2px; }
  header a:hover,
  header a:hover md-icon {
    color: #626262; }

.md-ripple-container, .md-ripple-placed {
  transition: none; }

.md-open-menu-container.md-active {
  opacity: 1;
  transition: none;
  transition-duration: 0ms;
  /* transition: all .4s cubic-bezier(.25,.8,.25,1); */
  /* transition-duration: 200ms; */ }

md-dialog.md-transition-in {
  opacity: 1;
  transition: none;
  -webkit-transform: none;
  transform: none; }

/*Disable transition for fading in menu items dialog.*/
.md-open-menu-container.md-active > md-menu-content > * {
  opacity: 1;
  transition: none;
  transition-duration: 0ms;
  transition-delay: 0ms; }

.md-button {
  transition: none;
  transition-duration: 0ms;
  transition-delay: 0ms; }

md-dialog._md-transition-in {
  transition: none;
  transition-duration: 0ms;
  transition-delay: 0ms; }

md-input-container label {
  transition: none !important;
  transition-duration: 0ms !important;
  transition-delay: 0ms !important; }

md-slider ._md-thumb-container, ._md-focus-ring, ._md-track-fill, ._md-thumb {
  transition: none !important;
  transition-duration: 0ms !important;
  transition-delay: 0ms !important; }

md-select-menu {
  transition: none !important;
  transition-duration: 0ms !important;
  transition-delay: 0ms !important; }

md-select-menu md-content {
  transition: none !important;
  transition-duration: 0ms !important;
  transition-delay: 0ms !important; }

md-input-container.md-input-focused:not(.md-input-has-value) md-select .md-select-value,
md-input-container.md-input-focused:not(.md-input-has-value) md-select .md-select-value.md-select-placeholder,
md-select-menu md-content md-option[selected],
md-select-menu md-content md-option[selected]:focus,
md-select-menu.md-default-theme md-content md-option,
md-select-menu md-content md-option {
  color: #7d7d7d; }

md-select:not([disabled]):focus .md-select-value {
  color: #7d7d7d; }

md-select.ng-invalid.ng-touched .md-select-value {
  color: #7d7d7d !important; }

md-select .md-select-value {
  padding: 0; }
  md-select .md-select-value .md-select-icon {
    width: auto;
    margin: 0;
    transform: none;
    margin-left: 5px; }
    md-select .md-select-value .md-select-icon::after {
      content: '\E313';
      font-family: 'Material Icons';
      display: initial;
      font-size: 18px; }

md-select:not([disabled]):focus .ng-invalid.ng-touched .md-select-value {
  padding: 0; }

md-select:not([disabled]).ng-invalid.ng-touched .md-select-value {
  padding: 0px; }

md-select-menu {
  position: absolute;
  text-align: left;
  padding: 10px 0;
  box-shadow: 0 2px 18px 4px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  z-index: 100; }
  md-select-menu:not(.md-overflow) md-content {
    padding: 0; }
  md-select-menu md-content md-optgroup > label {
    background: transparent;
    font-weight: bold;
    font-size: 0.8em; }
  md-select-menu md-content md-option {
    color: #7d7d7d;
    padding: 10px 75px 10px 20px;
    cursor: default;
    position: relative;
    outline: none;
    height: auto; }
    md-select-menu md-content md-option[selected] {
      background-color: transparent; }
    md-select-menu md-content md-option:not([disabled]):focus, md-select-menu md-content md-option:not([disabled]):hover {
      background-color: #f6f7fb; }
    md-select-menu md-content md-option[selected]::after {
      content: "check";
      font-family: Material Icons;
      position: absolute;
      top: 11px;
      right: 20px;
      color: #b7b7b7;
      font-size: 20px; }

md-checkbox.md-default-theme.md-checked .md-icon, md-checkbox.md-checked .md-icon {
  background-color: #ff3c58; }

button.md-button:not(.md-fab):not(.md-icon-button):not(.md-cancel-button) {
  padding: 0 40px;
  min-width: 160px;
  text-transform: capitalize; }

button.no-appearance {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 1px 6px; }

button.md-button.md-raised.md-primary {
  color: #ffffff;
  background-color: #ff3c58 !important;
  color: #ffffff !important; }
  button.md-button.md-raised.md-primary.md-cancel-button {
    background-color: #fff !important;
    color: #999999 !important; }
  button.md-button.md-raised.md-primary:active, button.md-button.md-raised.md-primary:hover {
    background-color: #ff3c58; }

button.md-button.md-link:not(.md-fab):not(.md-icon-button):not(.md-cancel-button) {
  margin: 0;
  padding: 0;
  min-width: 0;
  color: #999999; }
  button.md-button.md-link:not(.md-fab):not(.md-icon-button):not(.md-cancel-button):hover, button.md-button.md-link:not(.md-fab):not(.md-icon-button):not(.md-cancel-button):visited, button.md-button.md-link:not(.md-fab):not(.md-icon-button):not(.md-cancel-button):focus {
    background: transparent;
    text-decoration: underline; }

/* Modal
 * ---------------------------------------------------------------- */
md-backdrop.md-opaque {
  background-color: rgba(246, 250, 251, 0.95);
  opacity: 1; }

body md-dialog {
  box-shadow: 0 0 8px 12px rgba(0, 0, 0, 0.02) !important; }
  body md-dialog.md-default-theme {
    color: #7d7d7d;
    text-align: center;
    padding: 40px 0;
    border-radius: 0;
    box-shadow: 0 0 8px 12px rgba(0, 0, 0, 0.02); }
  body md-dialog.modal-tabs {
    padding: 0;
    min-width: 580px;
    min-height: 285px; }
    body md-dialog.modal-tabs md-dialog-content md-tab-item.md-tab {
      text-transform: initial; }
      body md-dialog.modal-tabs md-dialog-content md-tab-item.md-tab:hover:not(.md-active) {
        color: rgba(0, 0, 0, 0.8); }
    body md-dialog.modal-tabs md-dialog-content button.md-icon-button {
      position: absolute;
      top: 5px;
      right: 0;
      z-index: 2; }
    body md-dialog.modal-tabs md-dialog-content md-tabs md-tabs-wrapper {
      background: #f6f7fb; }
    body md-dialog.modal-tabs md-dialog-content md-tabs md-tabs-content-wrapper md-content {
      padding: 24px 42px;
      background-color: transparent; }
  body md-dialog md-dialog-content .md-dialog-content {
    padding: 0 42px 24px 42px; }
  body md-dialog md-dialog-content h2 {
    margin-bottom: 40px; }
  body md-dialog md-toolbar {
    background-color: #FFF !important; }
    body md-dialog md-toolbar .md-toolbar-tools md-icon {
      color: #999; }
  body md-dialog .md-actions,
  body md-dialog md-dialog-actions {
    justify-content: center;
    padding-left: 0;
    padding-right: 0; }
    body md-dialog .md-actions .md-confirm-button,
    body md-dialog md-dialog-actions .md-confirm-button {
      color: #FFF !important;
      padding: 2px 55px 1px;
      text-transform: none;
      margin-left: 0;
      background-color: #ff3c58 !important; }
      body md-dialog .md-actions .md-confirm-button:active, body md-dialog .md-actions .md-confirm-button:hover,
      body md-dialog md-dialog-actions .md-confirm-button:active,
      body md-dialog md-dialog-actions .md-confirm-button:hover {
        background-color: #ff3c58; }
    body md-dialog .md-actions .md-cancel-button,
    body md-dialog md-dialog-actions .md-cancel-button {
      min-width: 36px;
      color: #999 !important;
      font-family: 'Material Icons';
      background: none !important;
      position: absolute;
      top: 4px;
      right: 13px;
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -moz-font-feature-settings: 'liga';
      -moz-osx-font-smoothing: grayscale; }
  body md-dialog .labor-modal {
    color: #7d7d7d;
    padding: 40px 0;
    border-radius: 0;
    box-shadow: 0 0 8px 12px rgba(0, 0, 0, 0.02);
    font-size: 0.86em !important;
    font-family: "Open Sans", sans-serif !important; }
  body md-dialog .save-report {
    position: relative;
    bottom: 35px; }
    body md-dialog .save-report .save-report-icon {
      font-size: 28px; }
  body md-dialog.modal-form {
    padding-bottom: 40px;
    min-width: 580px; }
    body md-dialog.modal-form input,
    body md-dialog.modal-form input.md-input,
    body md-dialog.modal-form .md-text,
    body md-dialog.modal-form md-option.md-text {
      color: #484848; }
    body md-dialog.modal-form .md-dialog-content {
      padding: 24px 42px; }
    body md-dialog.modal-form md-dialog-actions button.md-raised.md-confirm-button.md-primary,
    body md-dialog.modal-form md-dialog-actions button.md-button.md-raised,
    body md-dialog.modal-form md-dialog-actions button.md-button:not([disabled]).md-raised:active,
    body md-dialog.modal-form md-dialog-actions button.md-button:not([disabled]).md-raised:focus,
    body md-dialog.modal-form md-dialog-actions button.md-button:not([disabled]).md-raised:visited,
    body md-dialog.modal-form md-dialog-actions button.md-button.md-raised:not([disabled]) {
      box-shadow: none;
      outline: none; }
  body md-dialog .modal-content-text {
    text-align: center;
    width: 300px; }
  body md-dialog md-datepicker .md-datepicker-button {
    margin: -12px 0 !important; }
  body md-dialog md-datepicker .md-datepicker-input-container {
    margin-left: 0px !important;
    width: 206px; }
  body md-dialog md-select.select-modal .md-text {
    font-size: 0.86em; }
  body md-dialog md-select.select-modal md-select-value {
    padding-bottom: 2px !important; }

.fullscreen-modal {
  padding: 3.2rem 0;
  align-self: start;
  min-width: 500px;
  background-color: transparent;
  box-shadow: none !important;
  position: initial;
  max-height: 100%;
  max-width: 100%;
  overflow: visible; }
  .fullscreen-modal .md-button.md-icon-button.close-button {
    position: absolute;
    top: 2rem;
    right: 2rem; }
    .fullscreen-modal .md-button.md-icon-button.close-button:hover {
      background-color: rgba(158, 158, 158, 0.2); }
  .fullscreen-modal md-dialog-content header {
    text-align: center;
    margin-bottom: 2.5rem; }
    .fullscreen-modal md-dialog-content header h2 {
      font-size: 2rem;
      margin: 0;
      font-weight: 500;
      letter-spacing: -1px; }
    .fullscreen-modal md-dialog-content header h3 {
      max-width: 500px;
      margin-top: 0.1rem;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.1rem;
      font-weight: 300; }
  .fullscreen-modal md-dialog-content input {
    font-size: 1.8rem;
    height: auto; }
  .fullscreen-modal md-dialog-actions {
    margin-top: 2.5rem;
    flex-direction: column; }
    .fullscreen-modal md-dialog-actions button.md-button:not(.md-fab):not(.md-icon-button):not(.md-cancel-button) {
      font-size: 1.3rem;
      padding: 0.7rem 1.5rem;
      border-radius: 0.25em; }
    .fullscreen-modal md-dialog-actions button.md-button.md-link:not(.md-fab):not(.md-icon-button):not(.md-cancel-button) {
      font-size: 1rem; }
      .fullscreen-modal md-dialog-actions button.md-button.md-link:not(.md-fab):not(.md-icon-button):not(.md-cancel-button) md-icon {
        font-size: 1.2rem;
        width: 1.2rem;
        height: 1.2rem; }

.welcome-modal {
  padding: 3.2rem; }
  .welcome-modal .md-button.md-icon-button.close-button {
    position: absolute;
    top: 2rem;
    right: 2rem; }
    .welcome-modal .md-button.md-icon-button.close-button:hover {
      background-color: rgba(158, 158, 158, 0.2); }

/* The switch:
 * ---------------------------------------------------------------- */
#wrapper md-switch .md-bar {
  width: 27px;
  height: 8px;
  position: absolute;
  top: 8px;
  left: 1px;
  border-radius: 8px; }

#wrapper md-switch.md-checked .md-bar {
  background-color: #ff7697; }

#wrapper md-switch .md-thumb {
  width: 16px;
  height: 16px;
  margin: 0;
  outline: none; }

#wrapper md-switch.md-checked .md-thumb::before {
  background-color: #ff395a; }

#wrapper md-switch[disabled] .md-bar {
  background-color: #e85d7e; }

#wrapper md-switch[disabled] .md-thumb::before {
  background-color: #e85d7e; }

#wrapper md-switch .md-thumb-container {
  top: 4px; }

/* LABOR TABLE
 * ------------------------------------------------------ */
.labor-full-table {
  width: 100%;
  color: #959595;
  border-collapse: collapse; }
  .labor-full-table > thead {
    text-align: left; }
  .labor-full-table > tbody {
    font-size: 1em;
    cursor: default;
    background-color: #FFF;
    border-top: 1px solid #eee; }
  .labor-full-table > thead > tr th,
  .labor-full-table > thead > tr td,
  .labor-full-table > tbody > tr th,
  .labor-full-table > tbody > tr td {
    font-weight: normal;
    position: relative;
    padding: 15px; }
    .labor-full-table > thead > tr th .actions,
    .labor-full-table > thead > tr td .actions,
    .labor-full-table > tbody > tr th .actions,
    .labor-full-table > tbody > tr td .actions {
      display: none;
      position: absolute;
      top: 30%;
      right: 25px; }
      .labor-full-table > thead > tr th .actions a md-icon,
      .labor-full-table > thead > tr td .actions a md-icon,
      .labor-full-table > tbody > tr th .actions a md-icon,
      .labor-full-table > tbody > tr td .actions a md-icon {
        color: #c7c7c7; }
        .labor-full-table > thead > tr th .actions a md-icon:hover,
        .labor-full-table > thead > tr td .actions a md-icon:hover,
        .labor-full-table > tbody > tr th .actions a md-icon:hover,
        .labor-full-table > tbody > tr td .actions a md-icon:hover {
          color: #949494; }
  .labor-full-table > thead > tr th:first-child,
  .labor-full-table > thead > tr td:first-child,
  .labor-full-table > tbody > tr th:first-child,
  .labor-full-table > tbody > tr td:first-child {
    padding-left: 40px; }
  .labor-full-table > thead > tr th:last-child,
  .labor-full-table > thead > tr td:last-child,
  .labor-full-table > tbody > tr th:last-child,
  .labor-full-table > tbody > tr td:last-child {
    position: relative;
    padding-right: 75px; }
  .labor-full-table > thead > tr:hover td .actions,
  .labor-full-table > tbody > tr:hover td .actions {
    display: block; }
  .labor-full-table md-switch {
    margin: 0; }

.labor-full-table tbody tr {
  border-bottom: 1px solid #eee;
  outline: none; }

table.chart-table {
  font-size: 0.86em; }
  table.chart-table tr.hover {
    font-weight: bold;
    color: #7d7d7d; }
    table.chart-table tr.hover td > .project-icon {
      width: 0;
      height: 0;
      display: inline-block;
      border-radius: 50%;
      border: 4px solid;
      margin-right: 5px; }

/* LABOR TOUR
 * ------------------------------------------------------ */
.introjs-tooltip {
  padding: 30px !important;
  min-width: 400px !important;
  font-size: 16px !important;
  color: #7d7d7d !important;
  text-align: center !important; }

.introjs-button {
  font-size: 14px !important;
  letter-spacing: .01em !important;
  border: none !important;
  border-radius: 2px !important;
  text-shadow: none !important; }
  .introjs-button.introjs-nextbutton, .introjs-button.introjs-donebutton {
    background: #ff3c58 !important;
    color: #FFF !important;
    margin-left: 5px !important; }
  .introjs-button.introjs-nextbutton.introjs-disabled {
    display: none !important; }
  .introjs-button.introjs-prevbutton {
    background: #EEEEEE !important;
    color: #8c8a8a !important; }
  .introjs-button.introjs-skipbutton {
    display: none !important; }
    .introjs-button.introjs-skipbutton.introjs-donebutton {
      display: inline-block !important; }

.introjs-tooltipbuttons {
  text-align: center !important;
  padding-top: 15px !important; }

.introjs-bullets {
  padding-top: 15px !important; }
  .introjs-bullets ul li a.active {
    background: #ff3c58 !important; }

.warning-tour {
  font-size: 12px !important; }

/*Notificações
*---------------------------------------*/
md-toast.md-success-theme .md-toast-content {
  background-color: #fff;
  border-left: 6px solid #5aa700;
  color: #484848; }
  md-toast.md-success-theme .md-toast-content::before {
    display: inline-block;
    content: "\E86C";
    font-family: "Material Icons";
    color: #5aa700;
    visibility: visible;
    min-height: auto;
    font-size: 1.5em; }

md-toast.md-error-theme .md-toast-content {
  background-color: #fff;
  border-left: 6px solid #e71d32;
  color: #000; }
  md-toast.md-error-theme .md-toast-content::before {
    display: inline-block;
    content: "\E14B";
    font-family: "Material Icons";
    color: #e71d32;
    visibility: visible;
    min-height: auto;
    font-size: 1.5em; }

md-toast.md-warning-theme .md-toast-content {
  background-color: #fff;
  border-left: 6px solid #efc100;
  color: #000; }
  md-toast.md-warning-theme .md-toast-content::before {
    display: inline-block;
    content: "\E002";
    font-family: "Material Icons";
    color: #efc100;
    visibility: visible;
    min-height: auto;
    font-size: 1.5em; }

@font-face {
  font-family: "Open Sans";
  src: url("/fonts/OpenSans-Regular-webfont.woff2") format("woff2"), url("/fonts/OpenSans-Regular-webfont.woff") format("woff"); }

.icon-svg {
  display: inline-block;
  font-size: 0;
  width: 18px;
  height: 18px;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%; }

.icon-add-blue {
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2216%22 height=%2216%22 viewBox=%220 0 16 16%22%3E%3Cpath fill=%22%234F587A%22 fill-rule=%22evenodd%22 d=%22M11.425 8.575h-2.85v2.85a.575.575 0 0 1-1.15 0v-2.85H4.574A.59.59 0 0 1 4 8c0-.317.257-.575.575-.575h2.85v-2.85C7.425 4.257 7.683 4 8 4c.145 0 .292.063.402.173.11.11.173.256.173.402v2.85h2.851A.59.59 0 0 1 12 8a.575.575 0 0 1-.575.575m2.03-6.424A7.93 7.93 0 0 0 7.721.006C3.313.159-.15 3.87.005 8.278a7.94 7.94 0 0 0 2.541 5.568 7.915 7.915 0 0 0 5.733 2.148c4.408-.154 7.869-3.865 7.716-8.273a7.952 7.952 0 0 0-2.54-5.57%22/%3E%3C/svg%3E"); }

.labor-btn {
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
  height: 35px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 14px;
  padding-left: 19.5px;
  padding-right: 19.5px;
  padding-top: 7px;
  padding-bottom: 7px; }
  .labor-btn.primary {
    color: #4f587a;
    background-color: #e8eaf3; }
  .labor-btn.secondary {
    color: #ffffff;
    background-color: #4f587a; }
  .labor-btn.labor {
    color: #ffffff;
    background-color: #ff3c58; }
  .labor-btn.icon-btn {
    padding-left: 15px;
    padding-right: 15px; }
    .labor-btn.icon-btn .icon {
      position: absolute; }
    .labor-btn.icon-btn .text {
      padding-left: 26px; }

.projects-select {
  -webkit-appearance: none;
  border: none;
  background: none;
  color: inherit;
  text-align: right; }
  .projects-select .projects-select__menu {
    width: inherit;
    margin-top: 0;
    margin-left: 28%;
    box-shadow: 0 2px 18px 4px rgba(0, 0, 0, 0.03); }
    .projects-select .projects-select__menu .projects-select__menu-list {
      padding: 0; }
      .projects-select .projects-select__menu .projects-select__menu-list .projects-select__option {
        cursor: pointer;
        color: #7d7d7d;
        font-size: 16px;
        opacity: 0.68;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        display: inline-block; }
        td .projects-select .projects-select__menu .projects-select__menu-list .projects-select__option {
          text-align: left;
          font-size: 14px;
          color: #7d7d7d;
          opacity: 1; }
      .projects-select .projects-select__menu .projects-select__menu-list .projects-select__option--is-focused {
        background-color: #f6f7fb; }
      .projects-select .projects-select__menu .projects-select__menu-list .projects-select__option--is-selected {
        background-color: #f6f7fb; }
    td .projects-select .projects-select__menu {
      margin-left: 0; }
  .projects-select .projects-select__control {
    background-color: inherit;
    border: none;
    cursor: pointer; }
    .projects-select .projects-select__control .projects-select__indicator svg {
      opacity: 0.68;
      color: #5f5f5f; }
    td .projects-select .projects-select__control .projects-select__indicator {
      padding-left: 0;
      color: #7d7d7d;
      opacity: 1; }
    .projects-select .projects-select__control .projects-select__value-container {
      color: #5f5f5f; }
      .projects-select .projects-select__control .projects-select__value-container .projects-select__single-value {
        width: 92%;
        color: #7d7d7d;
        font-size: 16px;
        opacity: 0.68;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right; }
      .projects-select .projects-select__control .projects-select__value-container .projects-select__placeholder {
        width: 92%;
        color: #7d7d7d;
        font-size: 16px;
        opacity: 0.68;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right; }
        td .projects-select .projects-select__control .projects-select__value-container .projects-select__placeholder {
          text-align: left;
          font-size: 14px;
          opacity: 1;
          color: #7d7d7d; }
      td .projects-select .projects-select__control .projects-select__value-container {
        padding-right: 0;
        padding-left: 0; }
    td .projects-select .projects-select__control {
      max-width: 140px;
      min-width: 50px; }
  .projects-select .projects-select__control--menu-is-open {
    box-shadow: none; }
  .projects-select .projects-select__control--is-focused {
    box-shadow: none; }

.users-select {
  -webkit-appearance: none;
  border: none;
  background: none;
  color: inherit;
  text-align: left; }
  .users-select .users-select__menu {
    width: inherit;
    box-shadow: 0 2px 18px 4px rgba(0, 0, 0, 0.03);
    margin-top: 20px; }
    .users-select .users-select__menu .users-select__menu-list {
      padding: 0; }
      .users-select .users-select__menu .users-select__menu-list .users-select__option {
        color: #7d7d7d;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        border-left: 2px solid #D7D7D7;
        padding-left: 10px;
        display: inline-block; }
      .users-select .users-select__menu .users-select__menu-list .users-select__option--is-focused {
        background-color: #f6f7fb; }
      .users-select .users-select__menu .users-select__menu-list .users-select__option--is-selected {
        background-color: #f6f7fb; }
        .users-select .users-select__menu .users-select__menu-list .users-select__option--is-selected::after {
          content: '';
          display: inherit;
          width: 6px;
          height: 12px;
          border: solid #959595;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          position: absolute;
          left: 135px;
          margin-top: 2px; }
  .users-select .users-select__control {
    background-color: inherit;
    border: none; }
    .users-select .users-select__control .users-select__indicator {
      padding: 0px; }
      .users-select .users-select__control .users-select__indicator svg {
        opacity: 0.68;
        color: #5f5f5f; }
    .users-select .users-select__control .users-select__indicator-separator {
      width: 0px; }
    .users-select .users-select__control .users-select__value-container {
      color: #5f5f5f;
      width: 135px; }
      .users-select .users-select__control .users-select__value-container .users-select__single-value {
        width: 85%;
        color: #7d7d7d;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        border-left: 2px solid #D7D7D7;
        padding-left: 10px; }
      .users-select .users-select__control .users-select__value-container .users-select__placeholder {
        width: 85%;
        color: #7d7d7d;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        border-left: 2px solid #D7D7D7;
        padding-left: 10px; }
  .users-select .users-select__control--menu-is-open {
    box-shadow: none; }
    .users-select .users-select__control--menu-is-open::before {
      content: "";
      background-color: #FF3C58;
      width: 125px;
      height: 1px;
      position: absolute;
      top: 54px;
      left: 16px; }
  .users-select .users-select__control--is-focused {
    box-shadow: none; }

.years-select {
  -webkit-appearance: none;
  border: none;
  background: none;
  color: inherit;
  text-align: left;
  width: 100px; }
  .years-select .years-select__menu {
    width: 100%;
    box-shadow: 0 2px 18px 4px rgba(0, 0, 0, 0.03); }
    .years-select .years-select__menu .years-select__menu-list {
      padding: 0; }
      .years-select .years-select__menu .years-select__menu-list .years-select__option {
        color: #7d7d7d;
        font-size: 16px;
        opacity: 0.68;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        display: inline-block; }
      .years-select .years-select__menu .years-select__menu-list .years-select__option--is-focused {
        background-color: #f6f7fb; }
      .years-select .years-select__menu .years-select__menu-list .years-select__option--is-selected {
        background-color: #f6f7fb; }
  .years-select .years-select__control {
    background-color: inherit;
    border: none; }
    .years-select .years-select__control .years-select__indicator {
      padding: 0px; }
      .years-select .years-select__control .years-select__indicator svg {
        opacity: 0.68;
        color: #5f5f5f; }
    .years-select .years-select__control .years-select__indicator-separator {
      width: 0px; }
    .years-select .years-select__control .years-select__value-container {
      color: #5f5f5f;
      width: 135px;
      padding: 0; }
      .years-select .years-select__control .years-select__value-container .years-select__single-value {
        width: 85%;
        color: #7d7d7d;
        font-size: 16px;
        opacity: 0.68;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left; }
      .years-select .years-select__control .years-select__value-container .years-select__placeholder {
        width: 85%;
        color: #7d7d7d;
        font-size: 16px;
        opacity: 0.68;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left; }
  .years-select .years-select__control--menu-is-open {
    box-shadow: none; }
  .years-select .years-select__control--is-focused {
    box-shadow: none; }

.labor-table {
  width: 100%; }
  .labor-table thead {
    font-size: 14px; }
    .labor-table thead tr {
      width: 100%;
      height: 49px;
      color: #5F5F5F;
      font-weight: 600; }
      .labor-table thead tr td:nth-child(1) {
        padding-left: 44px; }
  .labor-table tbody tr {
    width: 100%;
    height: 49px;
    border-top: solid 1px rgba(149, 149, 149, 0.2);
    border-bottom: solid 1px rgba(149, 149, 149, 0.2);
    background-color: #ffffff;
    font-size: 14px; }
    .labor-table tbody tr td:nth-child(1) {
      padding-left: 44px; }
    .labor-table tbody tr.blank-state {
      border: none;
      background-color: inherit; }
    .labor-table tbody tr .no-date {
      font-size: 12px;
      color: #A6A6A6; }
      .labor-table tbody tr .no-date .no-date__icon {
        font-size: 16px;
        margin-right: 5px; }
    .labor-table tbody tr .has-date {
      color: #7D7D7D; }
    .labor-table tbody tr .hours {
      position: relative;
      font-size: 12px;
      color: #959595; }
      .labor-table tbody tr .hours .hour {
        font-weight: bold; }
    .labor-table tbody tr table tr {
      border-top: 0;
      border-bottom: 0; }

.project-header-section {
  padding: 0 44px 30px 44px; }
  .project-header-section .project-header-title {
    height: 24px; }
    .project-header-section .project-header-title div:nth-of-type(1) {
      float: left;
      font-size: 24px;
      font-weight: 700;
      color: #4f587a; }
  .project-header-section .project-navigation {
    position: relative;
    height: 49px;
    margin: 16px 0;
    z-index: 0; }
    .project-header-section .project-navigation .project-navigation-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      float: left;
      height: 100%; }
      .project-header-section .project-navigation .project-navigation-btn-container .project-navigation-btn {
        font-weight: 400;
        margin-right: 48px;
        padding: 0 13px 8px 13px;
        overflow: hidden; }
      .project-header-section .project-navigation .project-navigation-btn-container .project-navigation-btn:last-child {
        margin-right: 0; }
      .project-header-section .project-navigation .project-navigation-btn-container .project-navigation-btn:hover {
        color: #4f587a; }
      .project-header-section .project-navigation .project-navigation-btn-container .project-navigation-btn_active {
        border-bottom: 3px solid #4f587a;
        font-weight: 600;
        color: #4f587a;
        z-index: 1; }
    .project-header-section .project-navigation .project-btn-add {
      float: right;
      font-size: 16px;
      margin-bottom: 12px; }
    .project-header-section .project-navigation .project-header-line {
      height: 1px;
      background-color: #d7d7d7;
      width: 100%;
      position: absolute;
      bottom: 1px;
      z-index: 0; }
  .project-header-section .project-filters {
    clear: both;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 25px; }
    .project-header-section .project-filters .project-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 24px; }
      .project-header-section .project-filters .project-filter span {
        font-weight: 400;
        font-size: 12px;
        margin: 0px 12px;
        color: #7d7d7d; }

.second-projects-table {
  margin-top: 20px; }

.project-table-body .opactity-50 {
  opacity: 0.5; }

.project-table-body .project-icon {
  margin-right: 10px;
  margin-bottom: 2px;
  color: var(--project-color); }

.project-table-body tr {
  cursor: pointer; }
  .project-table-body tr:hover td:nth-of-type(1) {
    font-weight: bold;
    color: var(--project-color); }

#task-bar {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  transition: top 0.3s linear;
  color: #7d7d7d;
  background-color: #FFF;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05); }
  #task-bar.nav-up {
    top: -120px; }
  #task-bar > div {
    margin: 1rem; }
    #task-bar > div md-input-container {
      margin: 0; }
    #task-bar > div > md-input-container:first-child {
      margin-bottom: 1rem; }
    #task-bar > div .task-bar__project md-input-container {
      overflow: hidden;
      margin-right: 2rem; }
    #task-bar > div .task-bar__planned-task {
      display: flex;
      margin-right: 3rem;
      width: max-content; }
  #task-bar .task-bar__submit button {
    margin: 0 0 0 1rem; }
  #task-bar .play-button {
    color: white; }
  @media (min-width: 600px) {
    #task-bar {
      width: 85%; }
      #task-bar.nav-up {
        top: -70px; }
      #task-bar > div {
        margin: 0.8rem 15px 0.8rem 40px; }
        #task-bar > div > md-input-container:first-child {
          margin-bottom: 0; }
      #task-bar md-input-container .md-input,
      #task-bar md-select.md-default-theme .md-select-value,
      #task-bar md-select .md-select-value,
      #task-bar md-select:not([disabled]):focus .md-select-value,
      #task-bar md-select.md-default-theme.ng-invalid.ng-touched .md-select-value,
      #task-bar md-select.ng-invalid.ng-touched .md-select-value {
        border: none !important; } }
  #task-bar md-select {
    display: inline-block; }
  #task-bar:after {
    content: '';
    display: block;
    clear: both; }
  #task-bar .md-button.md-fab {
    width: 2.3rem;
    height: 2.3rem;
    line-height: 2.3rem;
    margin-left: 1rem;
    background-color: #ff3c58; }
  #task-bar .md-button.md-fab:hover {
    background-color: #ff3c58; }

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #959595; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a {
  cursor: pointer; }

.align-container {
  margin-left: 40px;
  margin-right: 75px; }

*[ng-click] {
  cursor: pointer;
  outline: none; }

/* Helpers
 * ------------------------------------------------------ */
.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.clearfix:after {
  content: '';
  display: block;
  clear: both; }

.hide {
  display: none; }

.flip-horizontal {
  transform: scaleX(-1); }

.md-errors-spacer:empty {
  display: none; }

.selectedTab {
  color: #e91e63; }

.fixed-sidebar {
  position: fixed;
  width: 100%;
  height: 100%; }

.scrollable-content {
  overflow-y: scroll;
  width: 100%; }

.ellipsis-overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.inactive-icon {
  color: #959595; }

.tint-icon {
  color: #ff3c58; }

.min-width-10 {
  min-width: 10%; }

.min-width-15 {
  min-width: 15%; }

.min-width-20 {
  min-width: 20%; }

.min-width-25 {
  min-width: 25%; }

md-select-header,
md-optgroup > label {
  display: flex;
  padding: 10px;
  padding-left: 20px;
  background-color: rgba(233, 233, 233, 0.5); }

.payment-year-reference {
  padding-left: 15px;
  font-weight: bold; }

.project-text {
  font-size: 0.8em;
  font-weight: 900;
  text-transform: uppercase; }

.project-icon {
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid;
  margin-right: 5px; }

#drift-widget-container {
  display: none; }

@media (min-width: 600px) {
  #drift-widget-container {
    display: initial; } }
