/* LABOR TABLE
 * ------------------------------------------------------ */
.labor-full-table {
  width: 100%;
  color: rgb(149,149,149);
  border-collapse: collapse;

  & > thead {
    text-align: left;
  }

  & > tbody {
    font-size: 1em;
    cursor: default;
    background-color: #FFF;
    border-top: 1px solid #eee;
  }

  & > thead > tr,
  & > tbody > tr {
    th,
    td {
      font-weight: normal;
      position: relative;
      padding: 15px;
      .actions {
        display: none;
        position: absolute;
        top: 30%;
        right: 25px;
        a {
          md-icon {
            color: #c7c7c7;
            &:hover {
              color: darken(#c7c7c7, 20%);
            }
          }
        }
      }
    }

    th:first-child,
    td:first-child {
      padding-left: 40px;
    }

    th:last-child,
    td:last-child {
      position: relative;
      padding-right: 75px;
    }

    &:hover td .actions {
      display: block;
    }
  }
  md-switch {
    margin: 0;
  }
}

.labor-full-table tbody tr {
  border-bottom: 1px solid #eee;
  outline: none;
}

table.chart-table {
  font-size: 0.86em;
  tr.hover {
    font-weight: bold;
    color: #7d7d7d;
    td > .project-icon {
      width: 0;
      height: 0;
      display: inline-block;
      border-radius: 50%;
      border: 4px solid;
      margin-right: 5px;
    }
  }
}