planned-tasks-select {
  [popover-wrapper] {
    [popover-trigger] {
      opacity: 0.5;
      margin: 0;
      padding: 0;
      height: auto;
      width: auto;
      min-height: auto;
      min-width: auto;
      display: flex;
      align-items: center;

      &:hover {
        opacity: 1;
      }

      &.selected md-icon {
        color: #3BD788;
      }
    }

    .planned-tasks-select__popover__message {
      padding: 1rem;
      font-size: 0.9rem;
      display: flex;
      justify-content: center;

      span {
        padding: 0;
      }
    }

    list-select ul {
      max-height: 330px;
      overflow-y: auto;
      margin: 0.5rem 0;
    }
  }

  .planned-tasks-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    display: flex;
    opacity: 1 !important;


    .button-text {
      opacity: 0.8 !important;
    }

    .md-select-icon {
      width: auto;
      margin: 0;
      transform: none;
      margin-left: 5px;
      margin-top: 3px;

      &::after {
        content: '\E313';
        font-family: 'Material Icons';
        display: initial;
        font-size: 18px;
      }
    }
  }
}
