md-select.colorpicker {
  &:not([disabled]):focus md-select-value,
  md-select-value {
    border: none;
  }

  md-select-value {
    & > span:not(.md-select-icon) {
      -webkit-box-flex: initial;
      -ms-flex: initial;
      flex: initial;

      width: auto;
      margin: 0;
      transform: none;
      margin-left: 5px;

      .md-text {
        display: inline-block;
        span {
          display: inline-block;
          width: 1.5em;
          height: 1.5em;
          -webkit-border-radius: 0.25em;
          -moz-border-radius: 0.25em;
          border-radius: 0.25em;
        }
      }
    }
  }
}

.md-select-menu-container.colorpicker-menu md-select-menu {
  min-width: 150px;
  md-option {
    padding: 0;
    display: inline-block;
    position: relative;

    span {
      display: block;
      cursor: pointer;
      width: 1.5em;
      height: 1.5em;
      margin: 0.25em;
      -webkit-border-radius: 0.25em;
      -moz-border-radius: 0.25em;
      border-radius: 0.25em;
    }

    &[selected]::after {
      color: #ffffff;
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;
      font-size: 1.3em;
      line-height: 1.5em;
      width: 1.5em;
      text-align: center;
    }
  }
}