.labor-table {
  width: 100%;

  thead {
    font-size: 14px;

    tr {
      width: 100%;
      height: 49px;
      color: #5F5F5F;
      font-weight: 600;


      td:nth-child(1) {
        padding-left: 44px;
      }
    }
  }

  tbody {
    tr {
      td:nth-child(1) {
        padding-left: 44px;
      }

      width: 100%;
      height: 49px;
      border-top: solid 1px $grey-3-opactity-2;
      border-bottom: solid 1px $grey-3-opactity-2;
      background-color: $white;
      font-size: 14px;
      &.blank-state {
        border: none;
        background-color: inherit;
      }
      
      .no-date{
        font-size: 12px;
        color: #A6A6A6;
        .no-date__icon{
          font-size: 16px;
          margin-right: 5px;
        }
      }
      .has-date{
        color: #7D7D7D;
      }
      .hours{
        position: relative;
        font-size: 12px;
        color: #959595;
        .hour{
          font-weight: bold;
        }
      }
      table {
        tr {
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
  }
}
