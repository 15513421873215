// this file is only to apply animation on React Modal
// Using css is the only possible way according to documentation
// http://reactcommunity.org/react-modal/styles/transitions/

@keyframes zoomoutanimation {
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(0.5,0.5);
  }
  75% {
    transform: scale(0.25,0.25);
  }
  100% {
    transform: scale(0,0);
  }
}

.ReactModal__Overlay--before-close{
  animation: zoomoutanimation 200ms 1;
}