@mixin select-text-defaults {
  color: $grey-2;
  font-size: 16px;
  opacity: 0.68;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.years-select {
  -webkit-appearance: none;
  border: none;
  background: none;
  color: inherit;
  text-align: left;
  width: 100px;

  .years-select__menu {
    width: 100%;
    box-shadow: 0 2px 18px 4px rgba(0, 0, 0, 0.03);

    .years-select__menu-list {
      padding: 0;

      .years-select__option {
        @include select-text-defaults;
        display: inline-block;
      }

      .years-select__option--is-focused {
        background-color: $bg-grey;
      }

      .years-select__option--is-selected {
        background-color: $bg-grey;
      }
    }

  }

  .years-select__control {
    background-color: inherit;
    border: none;

    .years-select__indicator {
      padding: 0px;

      svg {
        opacity: 0.68;
        color: $grey;
      }
    }

    .years-select__indicator-separator {
      width: 0px;
    }

    .years-select__value-container {
      color: $grey;
      width: 135px;
      padding: 0;

      .years-select__single-value {
        width: 85%;
        @include select-text-defaults;
      }

      .years-select__placeholder {
        width: 85%;
        @include select-text-defaults;
      }
    }
  }

  .years-select__control--menu-is-open {
    box-shadow: none;
  }

  .years-select__control--is-focused {
    box-shadow: none;
  }

}
