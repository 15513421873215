project-dashboard {
  .project-dashboard-content {
    margin-bottom: 4rem;
  }

  & > header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid rgba(150, 150, 150, 0.3);
    padding: 0 0 0.875rem;
    margin-bottom: 1rem;

    .project-settings-actions {
      a {
        color: transparent;
        margin: 0 10px;

        
        &.active {
          svg {
            fill: #FF3C58;
          }
        }
      }

      button {
        margin: 0px !important;
        min-height: 0px !important;
      }


      svg {
        min-width: 24px;
        min-height: 18px;
        width: 18px;
        height: 18px;
        fill: #7D7D7D;

        &:hover, &.selected {
          fill: #FF3C58;
        }
      }
    }

    .header__title {
      display: flex;
      flex-direction: column;
      margin-top: -20px;

      p {
        margin: 0;
        font-size: 0.8rem;
        
        a {
          opacity: 0.6;
          color: #959595;
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 700;

          svg {
            color: #959595;
            font-weight: 700;
            font-size: 1rem;
            width: 1rem;
            height: 1rem;
            min-width: 1rem;
            min-height: 1rem;
            margin: 0;
          }

          &:hover {
            opacity: 1;
          }
        }

      }

      h1 {
        color: #5f5f5f;
        margin: 0;
      }
    }
  }

  section.project-dashboard-content {
    header.project-dashboard-content__submenu {
      margin-bottom: 1rem;
      display: flex;
      a {
        display: flex;
        align-items: center;
        color: #959595;
        font-weight: 500;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 700;
        padding: 0.3rem 1rem;
        opacity: .9;
        background-color: transparent;
        border: 1px solid rgba(150, 150, 150, 0.5);
        border-left-width: 0;

        &:first-child {
          border-left-width: 1px;
        }

        &:hover, &.selected {
          color: #FF3C58;
          background-color: rgba(150, 150, 150, 0.1);

          svg {
            color: #FF3C58;
          }
        }

        svg {
          margin-bottom: 0;
          margin-right: 0.7rem;
        }
      }
    }
  }
}