.boards-page-board-section-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0 !important;
}

trello-board-tile {
  width: 20%;
  padding: 0;
  margin: 0 2% 25px 0;
  -webkit-transform: translate(0);
  transform: translate(0);

  a.board-tile {
    background-size: cover;
    background-position: 50%;
    color: #fff;
    line-height: 20px;
    padding: 8px;
    position: relative;
    text-decoration: none;
    border-radius: 3px;
    display: block;

    .board-tile-fade {
      background-color: rgba(0,0,0,.15);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 3px;
    }

    &:hover {
      .board-tile-fade {
        background-color: rgba(0,0,0,.25);
      }
    }
  }

  .board-tile-details {
    display: flex;
    height: 80px;
    position: relative;
    flex-direction: column;
    justify-content: space-between;

    .board-tile-details-name {
      flex: 0 0 auto;
      font-size: 16px;
      font-weight: 700;
      display: inline-block;
      overflow: hidden;
      max-height: 40px;
      width: 100%;
      word-wrap: break-word;
    }
  }
}