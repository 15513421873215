header {
  margin-bottom: 40px;

  /* Estiliza barra de opções no header */
  &.header-options {
    border-bottom: 1px solid #d7d7d7;
    margin-left: 40px;
    margin-right: 75px;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    /* Remove border-bottom dos inputs */
    md-input-container .md-input,
    md-select.md-default-theme .md-select-value,
    md-select .md-select-value,
    md-select:not([disabled]):focus .md-select-value,
    md-select.md-default-theme.ng-invalid.ng-touched .md-select-value,
    md-select.ng-invalid.ng-touched .md-select-value {
      border: none !important;
    }

    md-input-container {
      margin: 0 10px 0 0;
      margin-bottom: -1px;
      border-bottom: solid 1px transparent;
      &:hover {
        border-bottom-color: #FF3C56;
      }
      .md-select-icon {
        margin-top: 4px;
        color: #959595;
      }
    }

    .header-icons button {
      &[disabled='disabled'] {
        opacity: 0.3;
        color: rgb(140, 140, 140);

        &:hover {
          color: rgb(140, 140, 140);
        }
      }
      .material-icons {
        color: rgb(160, 160, 160);
        font-size: 20px;

        &.enable {
          color: #ff3c58;
        }
      }

      &:hover .material-icons {
        color: rgb(140, 140, 140);
      }
    }
  }

  /* Estiliza links e ícones dos headers */
  a,
  md-icon {
    font-size: 1em;
    color: rgb(149, 149, 149);
  }

  md-icon {
    margin-bottom: 2px;
  }

  a:hover,
  a:hover md-icon {
    color: darken(rgb(149, 149, 149), 20%);
  }
}
