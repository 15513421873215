planned-templates {
  md-dialog-content {
    text-align: center;

    list-select ul {
      text-align: left;
      border: 1px solid rgba(0, 0, 0, 0.03);
      background-color: #f6f7fb;
      max-height: 150px;
      
      li:hover, li.selected {
        background-color: #efefef;
      }
    }
  }
}