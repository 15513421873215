trello-board-connection {
  min-width: 592px;

  header {
    text-align: initial !important;

    span {
      font-size: 1.5rem;
      font-weight: bold !important;
      color: #5f5f5f;
    }
  }

  section {
    .sub-header {
      color: #7d7d7d;
      font-weight: bold;
    }

    &.board-name {
      .sub-header {  
        margin-bottom: 45px;
      }

      .disclaimer {
        color: #7d7d7d;
        max-width: 592px;
        font-size: 0.8125rem;
      }

      .trello-board {
        width: 32%;
      }
    }

    &.board-lists {
      .sub-header {
        margin-top: 35px;
        margin-bottom: 15px;
      }

      .disclaimer {
        color: #5f5f5f;
        max-width: 592px;
        margin-bottom: 25px;
      }
    }

    &.label {
      .sub-header {
        margin-top: 10px;
        margin-bottom: 15px;
      }
      .disclaimer {
        color: #5f5f5f;
        max-width: 592px;
        margin-bottom: 25px;
      }
      button {
        background-color: #ff3c58;
        color: #FFF !important;
        font-size: 1rem !important;
        font-weight: 600 !important;
        text-transform: none !important;
        border-radius: 0px !important;
      }
    }
  }

  md-input-container {
    label {
      margin-bottom: 5px;
      -webkit-transform: translate3d(0,6px,0) scale(.75) !important;
      transform: translate3d(0,6px,0) scale(.75) !important;
      -webkit-transition: width .4s cubic-bezier(.25,.8,.25,1),-webkit-transform .4s cubic-bezier(.25,.8,.25,1) !important;
      transition: width .4s cubic-bezier(.25,.8,.25,1),-webkit-transform .4s cubic-bezier(.25,.8,.25,1) !important;
      transition: transform .4s cubic-bezier(.25,.8,.25,1),width .4s cubic-bezier(.25,.8,.25,1) !important;
      transition: transform .4s cubic-bezier(.25,.8,.25,1),width .4s cubic-bezier(.25,.8,.25,1),-webkit-transform .4s cubic-bezier(.25,.8,.25,1) !important;
      opacity: 1 !important;
      top: auto !important;
    }

    input {
      background-color: #ffffff !important;
      border: solid 1px rgba(149, 149, 149, 0.2) !important;
      padding: 14px 20px !important;
      font-size: 1rem !important;
    }

    md-select {
      background-color: #ffffff !important;
      border: solid 1px rgba(149, 149, 149, 0.2) !important;
      padding: 14px 20px !important;

      md-select-value {
        border-bottom-style: none !important;
        span {
          font-size: 1rem !important;
          font-weight: normal !important;

          &:nth-child(1) {
            &::after {
              content: '';
            }
          }
        }
      }
    }
  }

  .board-lists {
    md-input-container {
      &:last-child {
        margin-left: 47px;
      }
    }
  }

  md-dialog-actions {
    flex-direction: row !important;
    justify-content: left !important;
    
    button {
      padding: 1px 25px !important;
      font-size: 1rem !important;
      font-weight: 600 !important;
      text-transform: none !important;
      border-radius: 0px !important;

      &.cancel-board-button {
        background-color: #e8eaf3 !important;
        color: #4f587a !important;
        padding: 1px 25px !important;
        margin-left: 0px !important;
        min-width: 0px !important;
      }

      &.save-board-button {
        margin-left: 15px !important;
      }
    }
  }
}

md-backdrop.md-opaque {
  background-color: rgba(246, 247, 251, 0.97) !important;
}