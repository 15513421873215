[editable-as-input] {
  display: inline-block;
  cursor: pointer; }
  [editable-as-input] input {
    width: 350%;
    border: none;
    outline: none;
    padding: 0; }

[editable-as-select] {
  cursor: pointer; }
  [editable-as-select] md-select {
    display: inline-block;
    margin: 0; }
    [editable-as-select] md-select md-select-value,
    [editable-as-select] md-select:not([disabled]):focus .md-select-value {
      border: none; }
      [editable-as-select] md-select md-select-value .project-text,
      [editable-as-select] md-select:not([disabled]):focus .md-select-value .project-text {
        font-size: 1em; }
  [editable-as-select] input {
    width: 100%;
    border: none;
    outline: none; }

.editable-as-select-menu md-select-menu .project-text {
  font-size: 1em; }
