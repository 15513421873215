[editable-as-input] {
  display: inline-block;
  cursor: pointer;
  input {
    width: 350%;
    border: none;
    outline: none;
    padding: 0;
  }
}

[editable-as-select] {
  cursor: pointer;
  md-select {
    display: inline-block;
    margin: 0;

    md-select-value,
    &:not([disabled]):focus .md-select-value {
      border: none;

      .project-text {
        font-size: 1em;
      }
    }
  }
  input {
    width: 100%;
    border: none;
    outline: none;
  }
}

.editable-as-select-menu md-select-menu {
  .project-text {
    font-size: 1em;
  }
}