project-input {
  padding-bottom: 40px;
  input,
  input.md-input,
  .md-text,
  md-option.md-text {
    color: rgba(0,0,0,0.87);
  }
  .input-with-colorpicker {
    min-width: 520px;
    position: relative;
    input {
      padding-right: 5px;
    }
    .colorpicker {
      position: absolute;
      margin: 0;
      top: 0;
      right: 0;
    }
  }
  md-dialog-actions {
    button.md-raised.md-confirm-button.md-primary,
    button.md-button.md-raised,
    button.md-button:not([disabled]).md-raised:active,
    button.md-button:not([disabled]).md-raised:focus,
    button.md-button:not([disabled]).md-raised:visited,
    button.md-button.md-raised:not([disabled]) {
      box-shadow: none;
      outline: none;
    }
  }
  md-select:first {
    margin-right: 12px;
  }
  .select-modal {
    .md-text {
      font-size: 16px !important;
    }
    .md-select-value {
      padding-bottom: 2px !important;
    }
  }
  md-datepicker {
    .md-datepicker-input-container {
      width: 266px;
    }
  }

  .plus-info {
    button.md-button.md-link:not(.md-fab):not(.md-icon-button):not(.md-cancel-button) {
      display: inline-block;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.md-dialog-container {
  overflow: auto;
}

md-dialog.project-input {

  md-dialog-content {
    .project-categories {
      display: flex;
      justify-content: center;

      label.project-categories__card {
        cursor: pointer;
        background-color: #fff;
        width: 220px;
        font-weight: normal;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin: 0 .5rem;
        text-align: center;
        position: relative;
        background-color: rgba(255, 255, 255, 0.95);
        border-radius: 8px;
        padding: 1.618rem 0 0.809rem;
        box-shadow: inset rgba(150, 150, 150, 0.3) 0 0 0 1px, rgba(150, 150, 150, 0.2) 0 2px 20px;
        -webkit-transition: box-shadow .2s ease, background-color .2s ease;
        transition: box-shadow .2s ease, background-color .2s ease;
        padding: 1rem;
        box-sizing: border-box;

        &.project-categories__card--selected {
          box-shadow: inset #ff3c58 0 0 0 2px, rgba(150, 150, 150, 0.2) 0 1px 15px;
        }

        md-icon {
          color: rgba(0,0,0,0.87);
          font-size: 4rem;
          width: 4rem;
          height: 4rem;
        }

        h2 {
          font-size: 1.5rem;
          font-weight: 500;
          margin: 0.2rem 0;
        }

        p {
          font-weight: 300;
          font-size: 1rem;
          margin: 0 0 1rem;
        }

        md-radio-button {
          display: inline-block;
          margin: 0;
          width: 20px;
        }
      }
    }

    md-select.colorpicker md-select-value > span:not(.md-select-icon) .md-text span {
      width: 2.5em;
      height: 2.5em;
    }
  }

}
