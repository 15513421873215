@import './scss/_structure.scss';
@import './scss/_animations.scss';
@import './scss/_form.scss';
@import './scss/_buttons.scss';
@import './scss/_modals.scss';
@import './scss/_switchs.scss';
@import './scss/_tables.scss';
@import './scss/_tour.scss';
@import './scss/_notifications.scss';
@import '~intro.js/introjs.css';

@import '../react/scss/index.scss';

$primary-color: #ff3c58;

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: rgb(149, 149, 149);
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  cursor: pointer;
}

.align-container {
  margin-left: 40px;
  margin-right: 75px;
}

*[ng-click] {
  cursor: pointer;
  outline: none;
}

/* Helpers
 * ------------------------------------------------------ */

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

.hide {
  display: none;
}

.flip-horizontal {
  transform: scaleX(-1);
}

// Remove classe de erro quando está vazio
.md-errors-spacer:empty {
  display: none;
}

.selectedTab {
  color: #e91e63;
}

.fixed-sidebar {
  position: fixed;
  width: 100%;
  height: 100%;
}

.scrollable-content {
  overflow-y: scroll;
  width: 100%;
}

.ellipsis-overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inactive-icon {
  color: rgb(149, 149, 149);
}

.tint-icon {
  color: #ff3c58;
}

.min-width-10 {
  min-width: 10%;
}

.min-width-15 {
  min-width: 15%;
}

.min-width-20 {
  min-width: 20%;
}

.min-width-25 {
  min-width: 25%;
}

md-select-header,
md-optgroup > label {
  display: flex;
  padding: 10px;
  padding-left: 20px;
  background-color: rgba(233, 233, 233, 0.5);
}

.payment-year-reference {
  padding-left: 15px;
  font-weight: bold;
}

.project-text {
  font-size: 0.8em;
  font-weight: 900;
  text-transform: uppercase;
}

.project-icon {
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid;
  margin-right: 5px;
}

// Esconde o Drift no mobile
#drift-widget-container {
  display: none;
}

@media (min-width: 600px) {
  #drift-widget-container {
    display: initial;
  }
}
