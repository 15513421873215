.labor-btn {
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;

  height: 35px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 14px;

  padding-left: 19.5px;
  padding-right: 19.5px;
  padding-top: 7px;
  padding-bottom: 7px;


  &.primary {
    color: $dusk;
    background-color: $pale-grey;
  }

  &.secondary {
    color: $white;
    background-color: $dusk;
  }

  &.labor {
    color: $white;
    background-color: $labor;
  }

  &.icon-btn {
    padding-left: 15px;
    padding-right: 15px;

    .icon {
      position: absolute;
    }

    .text {
      padding-left: 26px;
    }
  }
}
