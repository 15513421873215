@mixin select-text-defauls {
  color: $grey-2;
  font-size: 16px;
  opacity: 0.68;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
}

.projects-select {
  -webkit-appearance: none;
  border: none;
  background: none;
  color: inherit;
  text-align: right;

  .projects-select__menu {
    width: inherit;
    margin-top: 0;
    margin-left: 28%;
    box-shadow: 0 2px 18px 4px rgba(0, 0, 0, 0.03);
    
    .projects-select__menu-list {
      padding: 0;
      
      .projects-select__option {
        cursor: pointer;
        @include select-text-defauls;
        display: inline-block;
        td &{
          text-align: left;
          font-size: 14px;
          color: #7d7d7d;
          opacity: 1;
        }
      }
      
      .projects-select__option--is-focused {
        background-color: $bg-grey;
      }
      
      .projects-select__option--is-selected {
        background-color: $bg-grey;
      }
    }
    td &{
      margin-left: 0;
    }
    
  }
  
  .projects-select__control {
    background-color: inherit;
    border: none;
    cursor: pointer;
    
    .projects-select__indicator {
      svg {
        opacity: 0.68;
        color: $grey;
      }
      td &{
        padding-left: 0;
        color:#7d7d7d;
        opacity: 1;
      }
    }
    
    .projects-select__value-container {
      color: $grey;
      
      .projects-select__single-value {
        width: 92%;
        @include select-text-defauls;

      }
      
      .projects-select__placeholder {
        width: 92%;
        @include select-text-defauls;

        td &{
          text-align: left;
          font-size: 14px;
          opacity: 1;
          color: #7d7d7d;
        }

      }

      td &{
        padding-right: 0;
        padding-left: 0;
      }

    }
    td &{
      max-width: 140px;
      min-width: 50px;
    }
  }
  
  
  .projects-select__control--menu-is-open {
    box-shadow: none;
  }

  .projects-select__control--is-focused {
    box-shadow: none;
  }
}
