button.md-button:not(.md-fab):not(.md-icon-button):not(.md-cancel-button) {
  padding: 0 40px;
  min-width: 160px;
  text-transform: capitalize;
}

button.no-appearance {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 1px 6px;
}

button.md-button.md-raised.md-primary {
  color: #ffffff;
  background-color: #ff3c58 !important;
  color: #ffffff !important;

  &.md-cancel-button {
    background-color: #fff !important;
    color: #999999 !important;
  }

  &:active,
  &:hover {
    background-color: #ff3c58;
  }
}

button.md-button.md-link:not(.md-fab):not(.md-icon-button):not(.md-cancel-button) {
  margin: 0;
  padding: 0;
  min-width: 0;
  color: #999999;

  &:hover, &:visited, &:focus {
    background: transparent;
    text-decoration: underline;
  }
}
