#top-progress-container {
  width: 85%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
}

#wrapper {
  padding-top: 152px;

  @media (min-width: 600px) {
    padding-top: 105px;
  }

  table {
    border-collapse: collapse;
  }
  &,
  & md-content {
    color: rgb(149, 149, 149);
    background-color: #f6f7fb;
  }
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    background-color: #FFF !important;
  }

  .new-version {
    margin: 0 75px 2rem 40px;
    padding: 0.5rem 1rem;
    background: rgba(#ff3c58, 0.8);
    font-weight: 600;
    font-size: 0.9rem;
    p,
    button {
      color: white;
      margin: 0;
    }
    button {
      margin: 0;
      padding: 0;
      width: auto;
      height: auto;
      min-width: auto;
      min-height: auto;
      line-height: 1rem;
      md-icon {
        margin: 0;
        padding: 0;
        color: white;
      }
    }
  }
}

.wrap-container {
  background-color: #FFF;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.fixed-sidebar-labor {
  position: absolute;
  width: 100%;
  height: 100%;
}

.sidebar-container {
  height: 100%;
}