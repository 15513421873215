.datepicker-parent {
  position: relative;
}

date-range-picker {
  div .calendar:first-child {
    margin-right: 15px;
  }
  div .calendar:last-child {
    margin-left: 15px;
  }
  div.month-selector {
    color: #fe3c57;
    text-align: center;
    text-transform: capitalize;
    margin: 30px 0;
    md-icon {
      color: #fe3c57;
      font-size: 1.2em;
      line-height: 1.5em;
    }
  }

  table {
    width: 100%;
    font-size: 1em;
    border-collapse: collapse;
    tbody {
      font-size: 0.86em;
      border: none;

      .week-day-line {
        cursor: default;
        span {
          pointer-events: none;
          font-weight: bold;
          font-size: 0.9em;
          color: #d6d6d6;
        }
      }

      tr {
        border: none;

        td {
          padding: 0;
          text-align: center;
          div {
            margin: 0.5em 0;
            height: 2.5em;

            &.period-line,
            &.period-line.start.end {
              background-color: #ffeaed;
            }

            &.period-line.start {
              background: linear-gradient(
                to right,
                transparent 0%,
                transparent 50%,
                #ffeaed 50%,
                #ffeaed 100%
              );
            }

            &.period-line.end {
              background: linear-gradient(
                to right,
                #ffeaed 0%,
                #ffeaed 50%,
                transparent 50%,
                transparent 100%
              );
            }

            span {
              cursor: pointer;
              color: #7d7d7d;
              border-radius: 50%;
              margin: 0;
              width: 2.5em;
              display: inline-block;
              text-align: center;
              line-height: 2.5em;

              &:hover {
                background-color: #f3f3f3;
              }
              &.today {
                background-color: #d7d7d7;
              }
              &.selected {
                background-color: #fe3b5b;
                color: #ffffff;
              }
              &.out {
                color: #e0e0e0;
              }
            }
          }
        }
      }
    }
  }
}
