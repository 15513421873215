/* LABOR TOUR
 * ------------------------------------------------------ */

.introjs-tooltip {
  padding: 30px !important;
  min-width: 400px !important;
  font-size: 16px !important;
  color: #7d7d7d !important;
  text-align: center !important;
}

.introjs-button {
  //@extend .md-button;
  font-size: 14px !important;
  letter-spacing: .01em !important;
  border: none !important;
  border-radius: 2px !important;
  text-shadow: none !important;

  &.introjs-nextbutton,
  &.introjs-donebutton {
    background: #ff3c58 !important;
    color: #FFF !important;
    margin-left: 5px !important;
  }
  &.introjs-nextbutton.introjs-disabled {
    display: none !important;
  }

  &.introjs-prevbutton {
    background: #EEEEEE !important;
    color: #8c8a8a !important;
  }

  &.introjs-skipbutton {
    display: none !important;
    &.introjs-donebutton {
      display: inline-block !important;
    }
  }
}

.introjs-tooltipbuttons {
  text-align: center !important;
  padding-top: 15px !important;
}

.introjs-bullets {
  padding-top: 15px !important;
  ul li {
    a.active {
      background: #ff3c58 !important;
    }
  }
}

.warning-tour {
  font-size: 12px !important;
}